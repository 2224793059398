// gatsby-config.js

exports.onInitialClientRender = () => {
  window.metrical = {
    app: "wM1D4yPJb",
  }
  const script = document.createElement("script")
  script.src = "https://api.metrical.xyz/script.js"
  document.head.appendChild(script)
}
